import React from 'react';
import content from '../../content/portfolio.yaml';
import Button from '../Button';

function Contact() {
  return (
    <div className="flex justify-center" data-testid="portfolio-cta">
      <Button
        variant="inverse"
        text={content.ctas.contact}
        to="/contact"
        className="w-11.5 h-6.5 lg:w-200px"
      />
    </div>
  );
}

export default Contact;
